import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { navLinks } from "src/constants";
import { Button } from "src/components/Button";
import { HamburgerIcon } from "src/assets/icons/HamburgerIcon";
import Logo from "src/assets/logo/logo-syndata.svg";

export const Navigation = () => {
  const [navbar, setNavbar] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  useEffect(() => {
    /*
    * adding the event when scroll change background
    * navbar scroll changeBackground function
    */
    window.addEventListener("scroll", () => {
      if (window.scrollY >= 66) {
        setNavbar(true);
      } else {
        setNavbar(false);
      }
    });
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
      setNavbar(true);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  return (
    <header
      className={`padding-x max-lg:px-0 max-lg:nav-background py-4 z-10 w-full absolute top-0 max-lg:bg-transparantOrange ${
        navbar ? "nav-background" : ""
      }`}
    >
      <nav className="flex flex-row justify-between items-center max-container max-lg:block">
        <Link to="/" className="flex justify-center max-lg:top-1 max-lg:right-0">
            <img
              src={Logo}
              alt="logo"
              width={150}
              height={29}
              className="m-0 w-[150px] h-[29px]  max-lg:p-2"
            />
        </Link>
        <span className="hidden max-lg:block absolute p-2 pl-5 top-0 mt-2" onClick={toggleNav}>
          <HamburgerIcon />
        </span>
        {(toggleMenu || screenWidth > 850) && (
          <ul className="flex max-md:flex-col max-lg:w-full max-lg:bg-lite-pink flex-1 justify-center items-center text-center gap-12 max-lg:gap-8 max-lg:pt-3">
            {navLinks.map((item) => (
              <li key={item.label}>
                <NavLink
                  to={item.link}
                  className="font-lexendgiga leading-normal text-sm max-lg:text-xs text-pitch-black hover:text-orange "
                  activeclassname="active"
                  onClick={()=>setToggleMenu(false)}
                >
                  {item.label}
                </NavLink>
              </li>
            ))}
          </ul>
        )}
        <div className="flex gap-2 text-lg leading-normal font-medium font-Lexendgiga max-lg:hidden wide:mr-24">
          <Button label="Sign In" bgColor="white" link="https://app.syndata.co" target="_blank" />
        </div>
      </nav>
    </header>
  );
};

