import { Button } from "src/components/Button";
import bgVideo from "src/assets/images/headervideo.mp4";
import { Typeonload } from "src/components/Typeonload";

export const Header = () => {
  const scrollToSection = () => {
    const mysection = document.getElementById('Register');
    if (mysection) {
      mysection.scrollIntoView({ behavior: 'smooth',  block: 'center',});
    }
  };
  return (
    <div className="h-screen xl:h-[700px]">
      <video src={bgVideo} autoPlay loop muted playsInline className="w-full h-full object-cover opacity-25"/>
      <article className="absolute w-full m-auto top-0 padding h-screen">
       <div className="flex-view m-auto flex-col padding-t max-md:pt-24 max-container">
         <h1 className="mt-12 font-lexendgiga capitalize text-[48px] text-center max-sm:text-[32px] max-sm:leading-[32px] font-bold padding-x max-lg:p-0">
           Accelerate your business with
           <div className="gradient-text h-20"><Typeonload  text="Synthetic Data"  delay="500" infinite/>
           </div>
         </h1>
         <article className="font-raleway text-black text-xl max-lg:text-lg p-2 sm:max-w-6xl text-center flex m-auto">
           <p>Complying with <span className="gradient-text">data </span>and  <span className="gradient-text"> AI</span> regulation doesn’t have to be a tradeoff
            between privacy and utility—Syndata is your innovation partner in optimizing your data strategy.
           </p>
         </article>
          <div className="padding-t flex-view flex-row gap-8 max-lg:flex-col">
           <Button label="Book a demo" bgColor="orange" link="https://syndataab.pipedrive.com/scheduler/PB86ROH8/demo-and-product-presentation-syndata"/>
           <Button label="Try It Yourself" bgColor="white" onClick={scrollToSection}/>
          </div>
        </div>
      </article>
    </div>
  )
};