import { LinkedinIcon } from "src/assets/icons/LinkedinIcon";
import { YoutubeIcon } from "src/assets/icons/YoutubeIcon";
import { AutoDeleteIcon } from "src/assets/icons/AutoDeleteIcon";
import { TestingIcon } from "src/assets/icons/TestingIcon";
import { EncryptedIcon } from "src/assets/icons/EncryptedIcon";
import { ShareIcon } from "src/assets/icons/ShareIcon";
import { InstagramIcon } from "src/assets/icons/InstagramIcon";
import { DiscordIcon } from "src/assets/icons/DiscordIcon";
import DataOrientation from "src/assets/images/data-orientation.png";
import KnowledgeHub from "src/assets/images/knowledge-hub.png";
import SyntheticData from "src/assets/images/synthetic-data.png";
import Synapp from "src/assets/images/synapp.png";
import AISwedenLogo from "src/assets/images/collaborationImages/ai-sweden-logo.png";
import ElectroluxLogo from "src/assets/images/collaborationImages/electrolux-logo.png";
import EuropenAiStartupLogo from "src/assets/images/collaborationImages/european-ai-startup-logo.png";
import IbmLogo from "src/assets/images/collaborationImages/ibm-logo.png";
import NordiclnnovationLogo from "src/assets/images/collaborationImages/nordicInnovation-logo.png";
import RiseLogo from "src/assets/images/collaborationImages/rise-logo.png";
import RvLogo from "src/assets/images/collaborationImages/rv-logo.png";
import TrustedAiStartupLogo from "src/assets/images/collaborationImages/trusted-ai-startup-sweden-logo.png";
import ShibuyaLogo from "src/assets/images/collaborationImages/shibuya-transparent-logo.png";
import DataPrivacy from "src/assets/images/usecasesImages/data-privacy.png";
import DataRetention from "src/assets/images/usecasesImages/data-retention.png";
import Finance from "src/assets/images/usecasesImages/finance-image.png";
import HealthCare from "src/assets/images/usecasesImages/healthcare-image.png";
import HealthCare2 from "src/assets/images/usecasesImages/healthcare-image2.png";
import Sharing from "src/assets/images/usecasesImages/sharing-images.png";
import Testing from "src/assets/images/usecasesImages/test-display.png";
import Dataset from "src/assets/images/usecasesImages/dataset-image.png";
import SynappEngine from "src/assets/images/synapp-engine-diagram.png";
import ClicksImg from "src/assets/images/clicks-image.png";
import ChooseModel from "src/assets/images/choose-model.png";
import EvaluationImage from "src/assets/images/evaluation-rightposition.png";
import ChooseModelleft from "src/assets/images/choose-model-left.png";
import DataRentationEvaluationNull from"src/assets/images/data-rentention-eva.nulll-left.png";
import SyntheticDataLeft from "src/assets/images/synthentic-data-left.png";
import Finance3 from "src/assets/images/finance3.png";
import DataElevation from "src/assets/images/data-elevations.png";

export const colorVariants = {
  white: "bg-transparant hover:bg-orange hover:text-white text-black ",
  orange: "bg-orange hover:bg-semi-orange text-white text-base hover:shadow-3xl ",
};

export const navLinks = [
  { link: "/solutions", label: "Solutions" },
  { link: "/usecases", label: "Use Cases" },
  { link: "/testimonials", label: "Testimonials" },
  { link: "/pricing", label: "Pricing" },
  { link: "/about", label: "About" },
  { link: "/contactus", label: "Contact us" },
];

export const footerLinks = [
  {
    title: "Learn More",
    links: [
      { name: "Solutions", link: "/solutions" },
      { name: "Use Cases", link: "/usecases" },
      { name: "Testimonials", link: "/testimonials" },
      { name: "Pricing", link: "/pricing" },
      { name: "About", link: "/about" },
      { name: "Privacy policy", link: "/privacypolicy" },
    ],
  },
  {
    title: "Get in touch",
    links: [
      { name: "info@syndata.co", link: "mailto:info@syndata.co" },
      {
        name: "Medborgarplatsen 25",
        link: "https://www.google.com/maps/place/Medborgarplatsen+25,+118+72+Stockholm/@59.3158652,18.0678864,17z/data=!3m1!4b1!4m6!3m5!1s0x465f77fac7c0361d:0x3fce39ea2b9a932b!8m2!3d59.3158652!4d18.0704613!16s%2Fg%2F11c3q3k0t6?entry=ttu",
      },
      { name: "+46 70 007 94 60" },
    ],
  },
];

export const solutionsItems = [
  {
    Image: SynappEngine,
    label: "Synthetic Data",
    text: "Your data has insight and value, but it probably also holds identities and vulnerabilities. Using generative AI, Syndata creates a synthetic copy of your data that retains the structure and correlations while eliminating sensitive information.",
  },
  {
    Image: ClicksImg,
    label: "Ease Of Use",
    text: "In just four clicks, you can generate synthetic data. We believe that data should be as simple as possible and that’s reflected in our app. The hard part is how to act on the insights, so first iterate with ease.",
  },
  {
    Image: SyntheticData,
    label: "Single or Multi-table",
    text: "Our synthetic generator is capable of single and multi-table synthesization both from uploaded csv files or database connections. We currently have two models to choose from, Random and Realistic, and our Evaluation Dashboard is packed with metrics to increase trust in the result.",
  },
  {
    Image: DataOrientation,
    label: "Data Orientation",
    text: "The opportunities of synthesization are dependant on your data maturity (and, to some extent, your data culture). To find out if synthetic data is even the right path, we offer a data orientation service to examine your process from a high-level.",
  },
  {
    Image: KnowledgeHub,
    label: "Knowledge Hub",
    text: "Our technical support team will work to provide you the necessary credentials and troubleshoot your database connection. Our data scientists will guide you on the effective way to get the most out of your synthetic generations.",
  },
  {
    Image: DataElevation,
    label: "Data Elevations",
    text: "Data is only as valuable as what you create from it. After enabling you to access, protect, augment, or store your data, we can manage the next steps like data management or machine learning.",
  },
];

export const socialMedia = [
  {
    src: <LinkedinIcon />,
    alt: "Linked In",
    link: "https://www.linkedin.com/company/syntheticdata-ftw/",
  },
  {
    src: <InstagramIcon />,
    alt: "Instagram",
    link: "https://www.instagram.com/syndataab/",
  },
  {
    src: <DiscordIcon />,
    alt: "Discord",
    link: "https://discord.gg/8vh5AbRbfk",
  },
  {
    src: <YoutubeIcon />,
    alt: "Twitter",
    link: "https://www.youtube.com/@syndatadotco",
  },
];

export const useCases = [
  {
    imgURL: <ShareIcon />,
    label: "Data Sharing",
    title: "Unlock the Value of Your Data",
    description:
      "The ability to migrate synthetic data to the cloud or share externally creates partnership and revenue opportunities",
    Questions: [
      {
        questionText: "Why is this a problem?",
        questionAnswer:
          "Siloed data within business units that don’t collaborate increases double work, incurs avoidable costs, and hinders data science performance.",
        questionImage: Sharing,
      },
      {
        questionText: "What can be done about it?",
        questionAnswer:
          "Insights are valuable, data is risky. An open data policy enables multiple teams to uncover different insights from the same data while guarding roles and security.",
        questionImage: EvaluationImage,
      },
      {
        questionText: "How does Syndata get the job done?",
        questionAnswer:
          "We will identify which datasets make sense to synthesize and which don’t. Our engine, Synapp, can then connect directly to your system and create new databases full of data free of restrictions or identities.",
        questionImage: Dataset,
      },
      {
        questionText: "In Healthcare",
        questionAnswer:
          "Nowhere is sharing data more essential and more problematic than in the medical field. The ability to share research insights and findings without betraying patient data will lead to breakthroughs and enable drugs and procedures a faster path to the market.",
        questionImage: HealthCare,
      },
      {
        questionText: "In Finance",
        questionAnswer:
          "Financial data is valuable to those looking to develop solutions for the industry or fintech startups looking to disrupt the status quo. Syndata enables data monetization without the regulatory restrictions of real data.",
        questionImage: Finance,
      },
    ],
  },
  {
    imgURL: <EncryptedIcon />,
    label: "Data Privacy",
    title: "From Real to Ideal",
    description:
      "Detractors of regulation believe privacy and utility to be a zero-sum game, but synthetic data achieves high scores in both.",
    Questions: [
      {
        questionText: "Why is this a problem?",
        questionAnswer:
          "Personal data is the currency of the internet. From social media to cookies to “free” airport WiFi, information that identifies you feeds advertising that funds the apps we love and rely on.",
        questionImage: DataPrivacy,
      },
      {
        questionText: "What can be done about it?",
        questionAnswer:
          "While tailored marketing is not all bad, it is possible to have the same utility of targeted advertising while also respecting privacy and complying with data protection regulation. Notice and consent satisfies, but synthetic data surpasses.",
        questionImage: ChooseModel,
      },
      {
        questionText: "How does Syndata get the job done?",
        questionAnswer:
          "Syndata’s Realistic model is more reliable than anonymization or psuedonymization as a PET (Privacy Enhancing Technology). The evaluation framework provides metrics you can trust and use to fine-tune your data..",
        questionImage: Synapp,
      },
      {
        questionText: "In Finance",
        questionAnswer:
          "Financial data is valuable to those looking to develop solutions for the industry or fintech startups looking to disrupt the status quo. Syndata enables data monetization without the security risks of personal information.",
        questionImage: Finance,
      },
      {
        questionText: "In Healthcare",
        questionAnswer:
          "Nowhere is data privacy more essential and more problematic than in the medical field. The ability to share research insights and findings without betraying patient data will lead to breakthroughs and enable drugs and procedures a faster path to the market.",
        questionImage: HealthCare,
      },
    ],
  },
  {
    imgURL: <TestingIcon />,
    label: "Testing Data",
    title: "Accelerate Your Business",
    description:
      "Software QA using synthetic data reduces the wait time for test data, increasing flexibility and agility during development.",
    Questions: [
      {
        questionText: "Why is this a problem?",
        questionAnswer:
          "Software development needs real-world data to test on. In-house dev teams have to wait for corporate approval. Data is a go-to-market bottleneck.",
        questionImage: Testing,
      },
      {
        questionText: "What can be done about it?",
        questionAnswer:
          "Real-world data doesn’t have to be real to be effective. Generating synthetic copies of corporate data accelerates quality assurance. Synthetic twins of industrial data can also be generated for profit or for the open source community.",
        questionImage: SyntheticDataLeft,
      },
      {
        questionText: "How does Syndata get the job done?",
        questionAnswer:
          "Our synthesizer engine, Synapp, currently offers two models: Random for when you need data quickly or Realistic when you need to mirror the real-world.",
        questionImage: ChooseModelleft,
      },
      {
        questionText: "In Finance",
        questionAnswer:
          "Fraud costs banks €5 for every €1 stolen. Machine learning models can help to predict when a transaction is fraudulent, but the rarity of the crime itself poses a data volume problem. Synthetic data alleviates this pain by allowing engineers to augment their test data appropriately.",
        questionImage: Finance3,
      },
      {
        questionText: "In Healthcare",
        questionAnswer:
          "Sometimes it’s cheaper to process data than hold onto it, but when building a robust machine learning model to predict hospital re-admissions, years of data will be required. Synthesizing your data for testing allows real-time, compliant model training.",
        questionImage: HealthCare2,
      },
    ],
  },
  {
    imgURL: <AutoDeleteIcon />,
    label: "Data Retention",
    title: "Keep Information Not Identities",
    description:
      "Storing value instead of data eases compliance risk and enables innovation across your organization.",
    Questions: [
      {
        questionText: "Why is this a problem?",
        questionAnswer:
          "Innovation costs risk, but regulation and security are outweighing the benefits. Data purpose protects the rights of users and consumers, but machine learning doesn’t know the purpose until it processes the data.",
        questionImage: DataRetention,
      },
      {
        questionText: "What can be done about it?",
        questionAnswer:
          "Machine learning requires large amounts of training data but it does not require original data subject to data retention laws. Synthetic data is the ideal solution to enable storage of data as long as you need it or until a purpose is more clearly defined.",
        questionImage: ChooseModel,
      },
      {
        questionText: "How does Syndata get the job done?",
        questionAnswer:
          "Our synthesizer engine, Synapp, preserves the relationships between your tables as well as the statistical structure of the insights within. The evaluation framework provides metrics you can trust and use to fine-tune your data.",
        questionImage: DataRentationEvaluationNull,
      },
      {
        questionText: "In HealthCare",
        questionAnswer:
          "Sometimes it’s cheaper to process data than hold onto it, but when building a robust machine learning model to predict hospital re-admissions, years of data will be required. Synthesizing your data for storage allows compliant model training that will help save lives without data leak risks.",
        questionImage: HealthCare2,
      },
      {
        questionText: "In Finance",
        questionAnswer:
          "Fraud costs banks €5 for every €1 stolen. Machine learning models can help to predict when a transaction is fraudulent, but the rarity of the crime itself poses a data volume problem. Synthetic data alleviates this pain by allowing safe long-term storage.",
        questionImage: Finance3,
      },
    ],
  },
];

export const teamMembers = [
  {
    imgURL: "https://mdbcdn.b-cdn.net/img/new/avatars/2.jpg",
    name: "Mattias Ripoll",
    title: "Cofounder - CEO",
    linkedin: "https://www.linkedin.com/in/mattiasripoll/",
  },
  {
    imgURL: "https://mdbcdn.b-cdn.net/img/new/avatars/2.jpg",
    name: "Guillermo Padrés",
    title: "Cofounder - CTO",
    linkedin: "https://www.linkedin.com/in/guillermo-padres-9259201/",
  },
];

export const collaborationsLogos = [
  {
    imgURL: AISwedenLogo,
    text: "",
  },
  {
    imgURL: ElectroluxLogo,
    text: "",
  },
  {
    imgURL: EuropenAiStartupLogo,
    text: "",
  },
  {
    imgURL: IbmLogo,
    text: "",
  },
  {
    imgURL: NordiclnnovationLogo,
    text: "",
  },
  {
    imgURL: RiseLogo,
    text: "",
  },
  {
    imgURL: RvLogo,
    text: "",
  },
  {
    imgURL: TrustedAiStartupLogo,
    text: "",
  },
  {
    imgURL: ShibuyaLogo,
    text: "",
  },
];

export const TeamValues = [
  {
    title: "Access",
    description:
      "In order to achieve our goals of enablement, users require access. We believe in the democratization of data; access leads to greater access.",
  },
  {
    title: "Trust",
    description:
      "The transparency of our application, the integrity behind our resolve, and the dignity of our customer fuels our purpose. Synthetic data is nothing without trust.",
  },
  {
    title: "Security",
    description:
      "Enabling generated data sharing requires data security that cannot be compromised. All it takes is one breach to destroy the compact with our customers",
  },
  {
    title: "Knowledge",
    description:
      "We enable our users to take back control of their data and unlock the value of their own assets without sacrificing others' control.",
  },
];

export const PricingCards = [
  {
    title: "Basic",
    cost: "Always Free",
    buttonLabel: "Get Started",
    Services: [
      {
        name: "Table Upload",
      },
    ],
    Usage: [
      {
        name: "Unlimited Users",
      },
      {
        name: "Conservative Input Limit",
      },
      {
        name: "Reasonable Generation Limit",
      },
    ],
    Implementation: [
      {
        name: "Web App",
      },
    ],
    Support: [
      {
        name: "Discord",
      },
    ],
  },
  {
    title: "Pro",
    cost: "€800/month",
    buttonLabel: "Contact us",
    buttonlink: "https://syndataab.pipedrive.com/scheduler/PB86ROH8/demo-and-product-presentation-syndata",
    Services: [
      {
        name: "Table Upload",
      },
      {
        name: "Database Connection",
      },
    ],
    Usage: [
      {
        name: "Unlimited Users",
      },
      {
        name: "Moderate Input Limit",
      },
      {
        name: "Distributed Generation Allowance",
      },
    ],
    Implementation: [
      {
        name: "SaaS",
      },
    ],
    Support: [
      {
        name: "Discord",
      },
      {
        name: "Email",
      },
    ],
  },
  {
    title: "Enterprise",
    cost: "from €3000/month",
    buttonLabel: "Contact us",
    buttonlink: "https://syndataab.pipedrive.com/scheduler/PB86ROH8/demo-and-product-presentation-syndata",
    Services: [
      {
        name: "Table Upload",
      },
      {
        name: "Database Connection",
      },
      {
       name: "Data Orientation",
      },
    ],
    Usage: [
      {
        name: "Unlimited Users",
      },
      {
        name: "Negligible Input Limit",
      },
      {
        name: "Purposeful Generation Allowance",
      },
    ],
    Implementation: [
      {
        name: "SaaS",
      },
      {
        name: "Bespoke",
      },
    ],
    Support: [
      {
        name: "Discord",
      },
      {
        name: "Email",
      },
      {
        name: "Dedicated Account Rep",
      },
    ],
  },
];
